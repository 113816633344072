
.gallery {
	.heading {
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			border-width: 15px;
			border-style: solid;
			border-color: #fff transparent transparent transparent;
		}
		position: relative;
		// margin: 1rem 0;
		min-height: 100%;
		// background: url(http://placekitten.com/1920/1080);
		background-position: center;
		background-size: cover;
		box-shadow: inset 0 0 0 2000px rgba(0,73,153);
		padding: 4rem 0;

		h1 {
			color: white;
			font-size: 2.5rem;
			text-align: center;
		}
	}
    .gallery-cell {
		margin: 2rem auto;
		.container {
			margin: 5rem auto;
		}
	}
    .carousel-item {
		height : 90%;
	}
	.carousel-item img {
		width: 100%;
		height: 100% !important;
		object-fit: cover;
	}
}



@media screen and (min-width: 1000px) {
	.gallery {
		.carousel-item {
			height : 600px;
		}
		.carousel-item img {
			width: 100%;
			height: 100% !important;
			object-fit: cover;
            object-position: top;
		}
	}
}

@media screen and (max-width: 500px) {
	.gallery {
		.gallery-cell {
			.container {
				text-align: center;
			}
		}
	}
}