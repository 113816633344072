.header_whole{
    width: 100%;
    max-width: 100%;
    background-color: #004999;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 2vh 0;
    color: white;
    font-size:1.2rem;
}
.jss_logo{
    display:absolute;
    height: 3rem;
    max-width: 100%;
}
.header_jss{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
